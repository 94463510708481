@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-spinel-stone-black: #242c3c;
    --color-secondary: #1e4857;
    --color-primary: #72a653;
    --color-spritzig: #71c1eb;
    --bg: #ffffff;
    --new-primary: #2c8ec6;
    --new-title-text: #3c4450;
    --new-sub-text: #8893a4;
    --new-bg: #eef1f5;
    --new-bg-fuel: #c7ddec;
  }
}

body {
  background-color: var(--bg);
  padding: 0!important;
  margin:0 !important;
  box-sizing: border-box;
  line-height: normal !important;
  font-family: Nunito Sans !important;
  font-style: normal !important;
}
#root{
  width: 100vw !important;
  height: 100vh !important;
  overflow: auto;
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to right,
    var(--color-primary),
    var(--color-spritzig)
  ); /* Replace with your desired colors */
}

.module-border-wrap {
  padding: 1rem !important;
  border-radius: 7px;
  position: relative;
  background-color: #2c8ec6 !important;
  padding: 10px !important;
  min-width: unset !important;
  text-transform: capitalize !important;
}

.module {
  background-color: var(--color-spinel-stone-black);
  border-radius: 7px;
  padding: 1px;
}

.selected-bg-gradient {
  box-shadow: 5px 5px 10px 0px rgba(179, 179, 179, 0.3) !important;
}

.selected-bg-gradient::before {
  content: "";
  height: 100%;
  width: 100%;
  display: inline-block;
  position: absolute;
  opacity: 0.20000000298023224;
  left: 0;
  top: 0;
  border-radius: 7px;
  background: var(--new-primary);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}
.MuiDialog-root .module-border-wrap-cancel {
  color: var(--new-primary) !important;
  font-size: 1em;
  padding: 0.6rem 2rem !important;
  border-radius: 7px !important;
  position: relative;
  border: 1px solid var(--new-primary) !important;
  /* background: linear-gradient(
    to right,
    var(--color-primary),
    var(--color-spritzig)
  ); */
  background-color: transparent !important;
  min-width: unset !important;
  text-transform: capitalize !important;
  margin-bottom: 2em;
}

.MuiDialog-root .module-border-wrap-logout {
  color: var(--bg) !important;
  font-size: 1em;
  padding: 0.6rem 2rem !important;
  border-radius: 7px !important;
  position: relative;
  border: 1px solid var(--new-primary) !important;
  background-color: var(--new-primary) !important;
  min-width: unset !important;
  text-transform: capitalize !important;
  margin-bottom: 2em;
}

.selected-bg-gradient-box {
  background-color: var(--bg);
  border-radius: 7px;
}

.selected-white-bg-box {
  background-color: var(--new-bg-fuel);
  border-radius: 7px;
}

.selected-white-bg::before {
  content: "";
  height: 100%;
  width: 100%;
  display: inline-block;
  position: absolute;
  opacity: 0.20000000298023224;
  left: 0;
  top: 0;
  border-radius: 7px;
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}
.dot-aftername {
  height: 0.3em;
  width: 0.3em;
  margin-top: 0.5em;
  margin-left: 0.4em;
  margin-right: 0.4em;
  border-radius: 50%;
  background-color: #fff;
}
optgroup {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1e4857;
  padding-top: 16px;
  padding-bottom: 2px;
}

.top-gradinet {
  background: linear-gradient(180deg, rgba(36, 44, 60, 0) 0%, #242c3c 84.28%);
}

.number-input .MuiInputBase-input {
  color: white;
}

.number-input .MuiFormHelperText-root {
  position: absolute;
  top: 13px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #afafaf;
}

.MuiOutlinedInput-input {
  padding: 14px !important;
}

/* remove hover on select */
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiTabs-scroller {
  /* overflow: visible !important; */
}

.MuiTabs-root {
  overflow: visible !important;
}

/*  */

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 1);
  background-color: #f5f5f5;
  border-radius: 15px;
}

*::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid rgba(44, 142, 198, 1);
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-Black.ttf") format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-BoldItalic.ttf")
      format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-BoldItalic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-Bold.ttf") format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-Bold.ttf") format("truetype0");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-Regular.ttf")
      format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-Light.ttf") format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-LightItalic.ttf")
      format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-LightItalic.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-ExtraBold.ttf")
      format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-ExtraBoldItalic.ttf")
      format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-ExtraBoldItalic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-ExtraLight.ttf")
      format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-ExtraLight.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-ExtraLightItalic.ttf")
      format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-ExtraLightItalic.ttf")
      format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-Italic.ttf") format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-SemiBold.ttf")
      format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../src/assets/Nunito_Sans/NunitoSans-SemiBoldItalic.ttf")
      format("truetype"),
    url("../src/assets/Nunito_Sans/NunitoSans-SemiBoldItalic.ttf")
      format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

.mn-height {
  min-height: 210px !important;
}

.text-map {
  position: absolute;
  width: 40;
  height: 40;
  border: 5px solid #f44336;
  border-radius: 40;
  background-color: white;
  text-align: "center";
  color: #3f51b5;
  font-size: 16;
  font-weight: bold;
  padding: 4;
}

.circle {
  background: #d83b01;
  border-radius: 50%;
  color: #fff;
  height: 2.5em;
  position: relative;
  width: 2.5em;

  border: 1px solid transparent;
}

.circleText {
  text-align: center;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.loader-animation {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--new-bg);
}

.loader-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader img {
  height: 8em;
  width: 8em;
  position: absolute;
  animation: spin 3.6s linear infinite;
}

.routes-loader,
.routes-loader .loader-container {
  height: 100%;
}

.routes-loader .loader-container .wrapper {
  display: flex;
}
.routes-loader .loader img {
  position: unset;
  height: 4em;
  width: auto;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.custom-info-window {
  background-color: #fff;
  padding: 0px;
  font-size: 25px;
  font-weight: 800;
  color: var(--color-secondary);
  text-transform: capitalize;
}

.gm-ui-hover-effect > span {
  display: none !important;
}

.align-items-center {
  align-items: center !important;
}
input::placeholder {
  color: var(--sub_text);
  font-size: 0.8em;
  font-weight: 100;
}

.input-mail-id {
  border-radius: 4px !important;
  width: 330px !important;
  padding: 10px;
  background: #ffffff !important;
  caret-color: #000;
  color: #565C68;
  line-height: 1rem;
  font-weight: 500;
  border: 1px solid #9e9e9e !important;
}

.input-mail-id:focus {
  border-radius: 4px !important;
  width: 330px !important;
  padding: 10px;
  font-weight: 500;
  background: #ffffff !important;
  box-shadow: none !important;
  outline: none !important;
  caret-color: #3c4450;
}

.Toastify__toast-theme--dark {
  background: linear-gradient(
    90deg,
    #384d2c 0%,
    #24333b 100%
  ) !important; /* Replace with your desired background color */
}
.Toastify__toast {
  box-shadow: 3px 3px 8px 0px rgba(37, 37, 37, 0.3) !important;
}

.icons-color {
  fill: #8893a4 !important;
}

.position {
  position: absolute;
}

.marker-label {
  transform: rotate(20deg); /* Adjust the rotation angle as needed */
  transform-origin: 50% 50%;
  font-size: 10px;
  font-weight: normal;
  margin-top: 0.5em;
}

.new-dropdown {
  padding-left: 0.5em !important;
  padding-right: 0.2em !important;
  padding-top: 0.5em !important;
  padding-bottom: 0 !important;
}

.hide-icon .MuiSelect-icon {
  display: none !important;
}

.text-black {
  color: #000 !important;
}
.text-white {
  color: #fff !important;
}
.text-underline {
  text-decoration: underline;
}
/* font classes */
.fs-14 {
  font-size: 14px;
}


.content-1::before {
  content: "\a";
  white-space: pre;
}

.disable:disabled {
  opacity: 0.6 !important;
}
.map-css > div:first-child {
  zoom: 53% !important;
}

.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

@media (max-width: 1400px) {
  .map-css > div:first-child {
    zoom: 46% !important;
  }
}

/* .css-10yxrb1-MuiInputBase-root-MuiOutlinedInput-root .MuiSvgIcon-root {
  color: #72a653 !important;
} */

.loadeder-animation .loader-container {
  height: 30em;
  width: 30em;
}

.list-items li {
  list-style: decimal;
  margin-top: 1.5em;
  letter-spacing: 0.5px;
  font-size: 1.2em;
  color: var(--new-title-text) !important;
}

.list-items li span {
  margin-left: 0.5em;
  color: #0284c4 !important;
}
.answer-content {
  color: #9e9e9e;
}
.answer-content ul {
  list-style-type: disc;
  padding-left: 1em; /* Adjust the padding as needed */
  color: #9e9e9e;
}

.input-mail--forgotpass {
  border-radius: 7px !important;
  width: 476px !important;
  height: 51px !important;
  padding: 1em;
  border: 1px solid var(--new-primary) !important;
  background: var(--bg) !important;
  caret-color: #000;
  color: #000;
}

.input-mail--forgotpass:focus {
  border-radius: 7px !important;
  width: 476px !important;
  height: 51px !important;
  padding: 1em;
  border: 1px solid var(--new-primary) !important;
  background: var(--bg) !important;
  box-shadow: none !important;
  outline: none !important;
  caret-color: #000;
}

.button-logout {
  position: fixed;
  right: 10px;
  top: 50%;
  z-index: 99;
  rotate: 90deg;
}

.btn-log-out-show {
  position: absolute !important;
  left: 30px;
}

.info-button {
  position: absolute;
  top: 0;
  margin-top: 1.5em;
  right: 0.5%;
}

.expand-buttons {
  position: absolute;
  right: -20%;
  top: 0%;
  margin-top: 4em;
}

.show {
  transition: all 0.8s;
  right: 0.2%;
  top: 0;
}

.hide {
  position: absolute;
  top: -10%;
}

.expand-buttons .nav-info,
.expand-buttons .nav-logout {
  width: 7em;
  padding: 0.5em 1em !important;
  display: block;
}

.vessel-detail-container .key {
  color: var(--new-primary);
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0.4em;
  text-transform: capitalize;
}

.vessel-detail-container .value {
  color: var(--black);
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0.4em;
}

.info-container {
  position: absolute;
  top: 3.5em;
  right: 8em;
  background-color: var(--bg);
  border-radius: 24px;
  box-shadow: 0px 0px 21.8px 0px rgba(0, 0, 0, 0.1);
  min-height: 10em;
  width: 25em;
  overflow: auto;
  padding: 1em 1em;
  z-index: 999999;
}

.info-container h3 {
  color: var(--new-primary);
  font-size: 1em;
}

.info-container li {
  margin-top: 1em;
}

.info-container li::marker {
  color: var(--new-primary);
}

.log-outmodal {
  backdrop-filter: blur(6px);
}

.log-outmodal.info {
  backdrop-filter: blur(0px) !important;
  width: 100% !important;
  padding: 2em;
}

.log-outmodal.info > div:nth-child(1) {
  background-color: transparent !important;
}

.logout-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-header {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.info-header h6 {
  color: var(--new-primary) !important;
}

.second-content .title {
  color: var(--new-primary) !important;
  letter-spacing: 1px;
  margin-top: 2em;
  padding-left: 2em;
}

.img-modal {
  padding-left: 2em;
}

.img-modal img {
  border-radius: 7px;
  height: 135px;
  width: 155px;
}
.title-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-header h5 {
  font-weight: 900 !important;
  font-size: 1.2em !important;
  color: var(--new-primary);
}

.box-container {
  position: relative;
  display: inline-block;
}

.boxes-modal {
  width: 176px;
  height: 88px;
  border-radius: 7px;
  background: var(--new-primary);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
  opacity: 0.2;
  margin-right: 1em;
}

.text-modal {
  position: absolute;
  top: 20px; /* Adjust the top position as needed */
  left: 15px; /* Adjust the left position as needed */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--new-title-text);
}

.text-modal-2 {
  position: absolute;
  top: 45px; /* Adjust the top position as needed */
  left: 16px; /* Adjust the left position as needed */
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--new-sub-text) !important;
}

.log-outmodal .info-text {
  color: #fff !important;
}

.modal-content {
  margin-top: 2em;
}

.first-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.second-content ul:nth-child(1) {
  list-style: disc !important;
  color: #fff !important;
  padding: 1em 2em !important;
}

.second-content .info-text ul li {
  margin-bottom: 0.7em;
}

.second-content .info-text ul:nth-child(1) {
  height: 8em;
  overflow-y: auto;
}
.second-content .info-text ul li ul li {
  margin-bottom: 0.2em;
}

.properties-table {
  border-radius: 16px;
  background: var(--new-bg);
  padding: 1em;
}
.properties-table-chart {
  border-radius: 16px;
  background: var(--new-bg);
  height: 492px;
  width: 650px;
  overflow-y: auto;
  position: relative !important;
}

.box-title {
  position: sticky;
  width: 100%;
  padding: 1em;
  top: 0; /* Stick to the top of the container */
  background: var(--new-bg); /* Match the background color of the container */
  z-index: 1; /* Ensure it appears above the table content */
}

.properties-table-chart .table-title {
  color: var(--new-primary);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.properties-table .table-title {
  color: var(--new-primary);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.table-key {
  color: var(--new-title-text) !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border: none !important;
  padding: 0.6em 0.6em !important;
}

.table-value {
  color: var(--new-sub-text) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border: none !important;
  padding: 0.6em 0.6em !important;
}

.properties-table .divider {
  height: 0.1em;
  margin-top: 1em;
  background-color: var(--new-sub-text);
}
.properties-table-chart .divider {
  height: 0.1em;
  margin-top: 1em;
  background-color: var(--new-sub-text);
}

.chart-detail {
  margin-top: 1em;
  height: 400px;
  width: 650px;
}

.log-outmodal .info-text li {
  list-style: disc;
  margin-bottom: 1.3em;
  color: var(--new-sub-text);
}

.info-text a {
  color: #0284c4;
}

.info-text ul {
  margin-left: 2em;
}

.examples {
  display: contents;
  flex-wrap: wrap;
  margin: -0.5em;
}
.containers {
  background-color: white;
  border-radius: 0.5em;
  box-sizing: border-box;
  flex: 0 0 30%;
  height: 640px;
  margin: 0.5em;
  overflow: auto;
  width: 100%;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  background-color: lightgray;
  margin: 0.25em;
  padding: 0.5em;
}

.boder-btn-new {
  position: relative !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 1em !important;
  padding: 1em 3.7em !important;
  cursor: pointer !important;
  background-color: transparent;
  border-radius: 7px !important;
  border: 1px solid var(--new-primary) !important;
}

.add-manualprice {
  background-color: transparent !important;
  border-radius: 7px !important;
  border: 1px solid #9e9e9e !important;
}

.add-manualprice button {
  color: var(--new-sub-text) !important;
  font-size: 14px !important;
}
.add-manualprice input {
  color: var(--new-title-text);
}

.add-manualprice input::placeholder {
  color: var(--new-title-text) !important;
  font-size: 14px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.box {
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-90%, -50%);
  cursor: pointer;
  z-index: 999;
}

.box span {
  width: 30px;
  height: 30px;
  display: block;
  border-right: 2px solid #8893a4;
  border-bottom: 2px solid #8893a4;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.box span:nth-child(2) {
  animation-delay: -0.2s;
}

.box span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: rotate(45deg) translate(10px, 10px);
  }
  100% {
    opacity: 1;
  }
}

.text-error {
  color: red;
}

.close-btn-placement {
  display: flex;
  justify-content: end;
  align-items: center;
}

.route-container {
  margin-left: 0 !important;
}

.padding-css {
  padding: 1em 2em;
}

.btn-priceforecast {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.price-forcast-container {
  justify-content: space-between;
  width: 58%;
  display: flex;
}

.price-by {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-action-container {
  display: flex;
  justify-content: center;
  gap: 2em;
}

.route-card {
  height: 100%;
}

.financial-conatiner,
.emmission-container {
  height: 770px;
}

.compliance-container,
.euets-container {
  height: 750px;
}

.info-icon {
  position: absolute;
  right: 0%;
}

.label-tab {
  width: 25em !important;
}

.reset-link {
  margin-left: 2rem;
}

.data-container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.btn-price-management {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.z-joule-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3em;
}
.login .z-joule-container {
  padding-right: 4rem;
}
.z-joule-container p {
  color: #9e9e9e;
  font-size: 0.8em;
  margin-bottom: 0;
}

.z-joule-container img {
  height: 100%;
  width: 7rem;
}
.sitelogo{
  width: 7rem;
}
.login-box {
  position: absolute;
  top: 30%;
}
.login-bg {
  position: absolute;
  z-index: -1;
}
.login-container {
  width: fit-content !important;
  margin: 0 auto !important;
  /* margin-left: auto; */
  /* margin-right: auto; */
  flex-direction: column !important;
  padding: 36px;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(102, 102, 102, 0.24) 0%, rgba(0, 0, 0, 0.30) 100%);
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(11.5px);
}
.login-heading {
  font-size: 25px;
  color: #fff;
  font-weight: 450;
  text-align: center;
  line-height: 30px;
}

.pdf-container {
  padding: 5em 8em;
  position: absolute;
  width: 100%;
  top: -11000%;
}

@media (max-width: 1590px) {
  .main-container-padding {
    padding: 0em 3em !important;
  }
}

@media (max-width: 1360px) {
  .btn-priceforecast {
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-top: 1rem !important;
  }

  .email-action-container {
    display: block;
    text-align: center;
    padding: 0 !important;
  }
}

@media (max-width: 1200px) {
  .price-forcast-container {
    width: 100%;
  }
  .route-card {
    height: auto;
    min-height: 19em;
  }
}
@media (max-width: 1000px) {
  .first-content {
    display: block;
  }
  .properties-table-chart {
    width: 100%;
    margin-top: 1em;
  }

  .chart-detail {
    width: 100%;
  }
}
@media (max-width: 945px) {
  .info-header {
    display: block;
  }
  .img-modal {
    padding: 0;
    padding-bottom: 1rem;
  }
  .port-select {
    margin-bottom: 1rem !important;
  }

  .route-card {
    height: auto;
    min-height: 19em;
  }
}
@media (max-width: 899px) {
  .btn-priceforecast {
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-top: 1rem !important;
  }
  .result-container > div {
    padding-left: 0 !important;
  }
  .email-action-container {
    display: block;
    text-align: center;
    padding: 0 !important;
  }

  .price-by {
    align-items: start;
  }
  .action-container {
    padding: 1em;
  }
  .tab-btn {
    height: 100% !important;
    width: 100% !important;
  }
  .tab-btn-gradient,
  .focus-class {
    height: 100% !important;
    width: 100% !important;
  }

  .result-tabs > div > div {
    align-items: center;
    width: 10em;
  }

  .result-tabs > div > svg {
    fill: #72a653;
    height: 1.5em;
    width: 1.5em;
  }

  .result-tabs .tab-btn,
  .result-tabs .tab-btn-gradient {
    margin-top: 0 !important;
  }

  .financial-conatiner,
  .emmission-container,
  .compliance-container,
  .euets-container {
    height: 620px;
  }

  .route-card {
    height: 100%;
    min-height: fit-content;
  }
}
@media (max-width: 599px) {
  .vessel-img-list {
    width: 40%;
  }

  .label-tab {
    width: 9em !important;
  }

  .hide-text {
    display: none;
  }

  .text-number {
    font-size: 1.6em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .info-container {
    width: auto;
  }
}
@media (max-width: 399px) {
  .vessel-img-list {
    width: 60%;
  }
  .price-forcast-container {
    display: block;
    width: auto;
  }
  .btn-priceforecast {
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
  }
  .btn-price-management {
    display: block;
    text-align: center;
  }
  .btn-price-management > button {
    margin-bottom: 0 !important;
    margin-top: 5px !important;
  }
}

.button-logout-responsive {
  display: none;
}

@media (max-width: 761px) {
  .box-container {
    width: 100%;
  }
  .button-logout {
    display: none !important;
  }

  .button-logout-responsive {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  .data-container {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .boxes-modal {
    width: 100%;
  }

  .padding-css {
    padding: 0.5em;
  }

  .first-content {
    display: block;
  }
  .properties-table {
    margin-bottom: 1rem;
  }
  .properties-table-chart {
    width: 100%;
  }

  .route-container {
    margin-left: 0 !important;
    margin-top: 3.5em !important;
  }
  .map-css {
    margin-bottom: 1em !important;
  }

  .chart-detail {
    width: 100%;
  }

  .info-header {
    display: block;
  }

  .img-modal {
    padding: 0;
    padding-bottom: 1rem;
  }

  .fuel-container {
    height: 100% !important;
    width: 100% !important;
  }

  .input-mail-id {
    width: auto !important;
    margin-bottom: 1rem;
  }
  .input-mail-id:focus {
    width: auto !important;
  }
  .result-cards-container {
    padding: 2.5rem 2rem;
  }
}

.error-box {
  display: flex;
  width: 491px;
  padding: 36px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.cross-icon{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.cross-icon-svg{
  width: 64px;
 height: 64px;
}

.gap {
  display: flex;
width: 491px;
padding: 36px;
flex-direction: column;
align-items: center;
gap: 24px;
}

.contact-us{
  display: flex;
padding: 12px 26px;
justify-content: center;
align-items: center;
gap: 10px;
}
.close-popup {
  width: "24px";
  height: "24px";
}

.close-popup:hover {
  background-color: #a7a4a4;
  color: #fff;
}

.header {
  width: 100%;
  padding: 23.5px 60px;
  justify-content: space-between;
  align-items: center;
  background: var(--z-joule-com-nero-nero, linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF);
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 2.5rem;
}

.faq-button {
  display: flex;
  width: 5.5rem;
  padding: .2rem 3rem;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  border-radius: 100px;
  border: 1px solid #2C8EC6;
  background: #FFF;
}

.faq-button:hover{
  background: #2C8EC6;
  color: #FFF !important;

}
.menu-avatar {
  width: 3.75em;
  height: 3.75em;
  flex-shrink: 0;
  border-radius: 2.5em;
  border: 2px solid var(--z-joule-com-nero-nero, #FFF);
  background: var(--sfsf-z-joule-com-lochmara, #0284C4);
}

.custom-user-info {
  color: var(--z-joule-com-shuttle-gray, #565C68);
  font-family: Nunito Sans !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  gap: 1.5rem;
  max-width: 16erm;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 1rem;
}
span{
  font-family: Nunito Sans !important;
}
.editor-menu-list-items {
  color: var(--z-joule-com-shuttle-gray, #565C68);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 0.625px;
}

.editor-menu-window {
  padding: 1.5em;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  align-self: stretch;
}

.settings-menu-window {
  display: flex;
  padding: 1.5em;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
  align-self: stretch;
  padding-bottom: 1.5em;
  padding-left: 0.625em;
}

.user-info {
  display: flex;
  padding-bottom: 0.75em;
  padding-top: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75em;
  align-self: stretch;
  position: relative; 
  height: 6rem;
}

.hamburger-dropdown {
  display: inline-flex;
  padding-bottom: 1.5em;
  font-family:  Nunito Sans !important;
  padding-top: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  max-width: 18rem;
}

.user-names{
  margin-top: 1.5rem;
  text-align: center;
  flex-direction: column;
  display: flex;
}


/* select box */
.selector-actions {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-right: 1em;
  margin-top: 1rem;
  width: 40% !important;
}
.select-box-container {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width:100% !important;
}
.select-box-container .select-box{
  align-items: center;
  background: #fff;
  border: .094em solid #adadad;
  border-radius: 1.375em;
  padding: .5rem 1rem;
}

.h-fit-content{
  height:fit-content !important;
}
.dropdown-menu-list {
  background: #fff;
  border-radius: 24px;
  box-shadow: 0 0 21.8px 0 #0000001a;
  display: block;
  left: 0;
  margin-top: 10px;
  position: absolute;
  top: 100%;
  padding: 1rem;
  transition: all .5s ease-in-out;
  width: 100%;
  z-index: 9;
}
.dropdown-container {
  max-height: 250px;
  overflow-y: auto;
}
.init.dropdown-item, .init.dropdown-item:hover {
  color: #9e9e9e;
  color: var(--sub_text);
}
.dropdown-item {
  border-bottom: 1px solid #f0f3f9;
  border-bottom: 1px solid var(--input_bg);
  color: #000;
  color: var(--black);
  font-size: .8em;
  font-weight: 500;
  padding-bottom: 12px;
  padding-top: 12px;
}
.dropdown-item {
  background-color: initial;
  border: 0;
  border-radius: 0;
  clear: both;
  color: var(--bs-dropdown-link-color);
  display: block;
  font-weight: 400;
  padding: 1rem 0.5rem;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}

.customLegendBorder.active {
  border: 1px solid #9e9e9e;
  padding: 0.3rem;
  width: fit-content;
  margin: 0 auto;
}